<template scoped>
  <div>
    <el-form-item
      :rules="rules.rfcomm"
      label="RFCOMM"
      prop="connect.bluetooth.classic.rfcomm"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="logic.connect.bluetooth.classic.rfcomm"
        placeholder="00000000-0000-0000-0000-000000000000"
      >
        <el-switch
          slot="append"
          v-model="logic.connect.bluetooth.classic.rfcommValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>
  </div>
</template>
<script scoped>
export default {
  props: {
    logic: Object,
  },
  methods: {
    validator_rfcomm: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the rfcomm should not be empty"));
      } else {
        callback();
      }
    },
  },
  data: function () {
    return {
      rules: {
        rfcomm: [{ validator: this.validator_rfcomm, trigger: "blur" }],
      },
    };
  },
};
</script>